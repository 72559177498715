.footer {
    color: grey; /* White text */
    padding: 20px 0;
}

.footer h5 {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #fff; /* White text */
}

.footer ul {
    padding-left: 0;
    list-style-type: none;
}

.footer a {
    color: #fff; /* White text */
    text-decoration: none;
}

.footer address {
    font-style: normal;
    color: #fff; /* White text */
}

.footer p {
    margin: 0;
}

.bottom-line {
    text-align: right;
    margin-top: 20px;
}
