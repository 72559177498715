/* Common styles for images */
img {
    width: 100%;
    height: auto;
    object-fit: cover;  /* Ensures the image fills its container without distortion */
    border-radius: 10px; /* Adds rounded corners */
    transition: transform 0.3s ease; /* Smooth zoom effect on hover */
  }
  
  .container {
    padding: 0 15px;
    margin-top: 20px; /* Ensures space at the top of the container */
  }
  
  .our-products-section {
    text-align: center;
  }
  
  .our-products-section h2 {
    margin-bottom: 1em;
  }
  
  .our-products-section .product,
  .category,
  .subcategory {
    margin-bottom: 1em;
    cursor: pointer;
    text-align: center;
  }
  
  .our-products-section .product img,
  .category img,
  .subcategory img {
    width: 100%;
    height: 300px; /* Consistent height for category, subcategory, and product images */
    border: 1px solid #333; /* Border for product images */
  }
  
  .our-products-section .product img {
    border: 1px solid #333; /* Border for product images */
  }
  
  .our-products-section .product p,
  .category p,
  .subcategory p {
    margin-top: 0.5em;
    transition: color 0.3s ease;
  }
  
  .our-products-section .product:hover img,
  .category:hover img,
  .subcategory:hover img {
    transform: scale(1.05); /* Zoom effect on hover */
  }
  
  .our-products-section .product:hover p,
  .category:hover p,
  .subcategory:hover p {
    color: #63be78; /* Color change on hover */
  }
  
  .filter-label {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    cursor: pointer;
  }
  
  .pagination button:hover {
    background-color: #e9e9e9;
  }
  
  @media (max-width: 1300px) {
    .our-products-section .product img,
    .category img,
    .subcategory img {
      height: 150px; /* Adjust height for smaller screens */
    }
  }
  
  @media (max-width: 767px) {
    .our-products-section .product,
    .category,
    .subcategory {
      flex: 0 0 50%;
      max-width: 50%;
    }
  
    .our-products-section .product img,
    .category img,
    .subcategory img {
      height: 150px; /* Adjust height for smaller screens */
    }
  }
  