.welcome-section {
    margin: 2em 0;
}

.welcome-section h2 {
    margin-bottom: 1em;
}

.welcome-section p {
    margin-bottom: 1em;
}

.contact-top-image img {
    width: 100%;
    height: auto;
}

.text-block {
    padding: 20px;
}

/* Additional CSS to remove default padding from Bootstrap's column */
.col-md-6 {
    padding: 0;
}

.address-text {
    color: #333;
}

.map-container {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    position: relative;
}

.map-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.contact-heading {
    margin-bottom: 20px;
}
