img {
    width: 500px;  /* Set width to 500px */
    height: 300px; /* Set height to 300px */
    object-fit: cover;  /* Ensures the image is resized to fill the 500x300 area without distortion */
}

.welcome-section {
    margin: 2em 0;
}

.welcome-section h2 {
    margin-bottom: 1em;
}

.welcome-section p {
    margin-bottom: 1em;
}

.product-showcase {
    margin: 2em 0;
}

/* Style for carousel */
#productCarousel {
    margin-bottom: 2em;
}

.our-products-section .product img,
.carousel-item img {
    width: 500px;  /* Set width to 500px */
    height: 300px; /* Set height to 300px */
    object-fit: cover;  /* Ensures the image is resized to fill the 500x300 area without distortion */
    border-radius: 10px; /* Optional: for rounded corners */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%; /* Circular background */
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.our-products-section h2 {
    margin-bottom: 1em;
}

.our-products-section .product {
    margin-bottom: 1em;
}

/* Add a hover effect to the product div that contains both the image and the text */
.our-products-section .product:hover img {
    transform: scale(1.05); /* Grow the image size */
    transition: transform 0.3s ease; /* Animation for the transform */
}

.our-products-section .product:hover p {
    color: #63be78; /* Change text color to green */
    transition: color 0.3s ease; /* Animation for the color change */
}

/* You might want to add a slight transition effect to the images and paragraphs so that the change is smooth */
.our-products-section .product img {
    max-width: 100%;
    height: auto;
    border-radius: 10px; /* Optional: for rounded corners */
    transition: transform 0.3s ease; /* Smooth transition for growing effect */
}

.our-products-section .product p {
    margin-top: 0.5em;
    transition: color 0.3s ease; /* Smooth transition for color change */
}

.macaw-link {
    display: block;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  
  .macaw-logo {
    max-width: 200px; /* Adjust the size as needed */
    margin-bottom: 0.5em;
  }
  
  .embed-container {
    position: relative;
    overflow: hidden;
    margin-bottom: 2em;
  }
  
  .embed-container a {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    position: relative;
  }
  
  .embed-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }