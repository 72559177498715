.product-list {
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 20px;
  padding-top: 50px;
}

.category-management {
  margin-bottom: 30px;
}

.category-management h2,
.product-listing h2 {
  margin-bottom: 15px;
}

.category-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.category-image {
  width: 100px;
  height: auto;
  display: block;
  margin-left: 10px;
}

.product-listing {
  margin-top: 20px;
}

.product-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.product-item-name {
  margin-right: 10px;
}

.product-image {
  width: 100px;
  height: auto;
  display: block;
  margin-bottom: 10px;
}

.add-product-btn,
.add-category-btn,
.save-product-btn,
.delete-product-btn,
.delete-category-btn {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-product-btn {
  margin-bottom: 15px;
  background-color: #4CAF50;
  color: white;
}

.add-category-btn {
  margin: 5px 15px;
  background-color: #4CAF50;
  color: white;
}

.add-category-btn:hover,
.add-product-btn:hover {
  background-color: #45a049;
}

.save-product-btn {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  margin-bottom: 5px;
}

.save-product-btn:hover {
  background-color: #45a049;
}

.delete-product-btn {
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
}

.delete-product-btn:hover {
  background-color: #d32f2f;
}

.delete-category-btn {
  margin: 5px 15px;
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
}

.delete-category-btn:hover {
  background-color: #d32f2f;
}

.new-product-form,
.new-category-form {
  margin-bottom: 15px;
}

.new-product-form input,
.new-product-form select,
.new-product-form textarea {
  margin-right: 5px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.new-product-form textarea {
  width: 100%;
  resize: vertical;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
