.header {
    background-color: #ffffff;
    padding: 20px 0;
    position: relative; /* Ensure header is positioned for absolute child positioning */
}

.logo img {
    max-width: 100%;
    height: auto;
}

.nav .nav-item {
    margin-bottom: 0;
}

.nav .nav-link {
    color: #333;
    padding: .5rem 0.5rem;
    transition: color 0.3s;
}

.nav .nav-link:hover {
    color: #63be78;
}

.nav .nav-link.active {
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 8px;
}

/* Dropdown styles */
.nav .dropdown {
    position: static; /* Required for correct positioning of dropdown menu */
}

.nav .dropdown-menu {
    position: absolute; /* Position it relative to the nav item */
    z-index: 1000; /* Ensure it's above other content */
}

@media (max-width: 991px) {
    .nav {
        flex-direction: row; /* Keep items in one line */
        flex-wrap: nowrap; /* Prevent wrapping */
    }
}
