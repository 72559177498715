/* Custom styles for ProductDetails component */
.product-detail-image {
  max-height: 400px; /* Adjust the max height as needed */
  object-fit: cover; /* This will make sure the image covers the card area */
}

.product-thumbnail {
  max-width: 100px;
  height: auto;
  margin: 5px;
  cursor: pointer;
  opacity: 0.6;
}

.product-thumbnail.selected {
  border: 2px solid #63be78; /* Highlight the selected thumbnail */
  opacity: 1;
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 1.5rem;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.card-text {
  margin-bottom: 0.5rem;
}

.form-label {
  font-weight: 600;
}

.form-control {
  margin-bottom: 1rem;
}
