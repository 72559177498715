.welcome-section {
    margin: 2em 0;
}

.welcome-section h2 {
    margin-bottom: 1em;
}

.welcome-section p {
    margin-bottom: 1em;
}

.about-top-image img {
    width: 100%;
    height: auto;
}

.text-block {
    padding: 20px;
}

/* Additional CSS to remove default padding from Bootstrap's column */
.col-md-6 {
    padding: 0;
}

.about-us-text {
    text-align: left;
}
